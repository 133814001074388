.pallet-body {
    width: 100%;
    height: 92%;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.screen-po {
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
    height: 100%;
}
.screen-po h1 {
    align-self: center;
    font-size: 10vw;
}

.search-criteria {
    position: sticky;
    /* top: 10px; */
    z-index: 9;
    height: 25%;
    min-height: 220px;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    background-color: #f2f2f2;
    margin-top: 4px;
}

.search-result {
    height: 60%;
}

.ios .search-criteria {
    margin-top: 6px;
    margin-bottom: 11px;
}

.search-criteria-header {
    padding: 3px 5px;
}

.pallet-section {
    overflow: auto;
}

.search-result-data {
    height: 85%;
    overflow-y: auto;
    overflow-x: auto;
}


/* Fields Css*/

.search-criteria ion-input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    background-color: #ffffff;
    font-size: 12px;
    color: #000000;
}

.search-criteria ion-select {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    background-color: #ffffff;
    display: inline-flex;
    --padding-top: 5px;
    --padding-bottom: 0px;
    font-size: 12px;
    color: #000000;
}

.data-label {
    font-weight: bold;
    display: block;
}

.barcode-items {
    margin: 5px;
    background-color: #f2f2f2;
}

.pallet-info {
    background-color: #ffffff;
}

ion-label>strong {
    color: #000000;
    font-weight: normal;
}

.search-result-data ion-row:nth-child(even) {
    /* border-bottom: 1px solid #0000001c; */
    background-color: #f2f2f2;
}

.head-label {
    font-size: 20px;
}

.card-action {
    height: 35px;
    padding: 7px;
    display: flex;
    flex-direction: row-reverse;
}

.item-card-action {
    height: 35px;
    padding: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.item-head-icon {
    width: 5%;
    min-width: 20px;
    height: 96%;
    float: right;
    cursor: pointer;
    padding: 0 5px 0 10px;
}

.trash-icon {
    color: red;
}

.head-label-small {
    vertical-align: -webkit-baseline-middle;
}

ion-card-header+.card-content-md {
    padding: 0;
}

.action-btn {
    font-size: 12px;
    padding: 9px 3px;
    height: 100%;
}

@media screen and (min-width: 480px) {
    .pallet-body {
        width: 70%;
        min-width: 500px;
        height: 91%;
        overflow-y: auto !important;
        overflow-x: auto !important;
        align-items: center;
        align-self: center;
    }

    .screen-po {
        display: flex;
        align-content: center;
        align-self: center;
        justify-content: center;
        height: 100%;
    }
    .screen-po h1 {
        align-self: center;
        font-size: 10vw;
    }
    .search-criteria {
        position: sticky;
        top: 10px;
        z-index: 9;
        height: 25%;
        min-height: 148px;
        padding: 0;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 5px;
        background-color: #f2f2f2;
        margin-top: 10px;
    }
    .search-criteria ion-input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        height: 26px;
        background-color: #ffffff;
    }
    .search-criteria ion-select {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        height: 26px;
        background-color: #ffffff;
        display: inline-flex;
        --padding-top: 4px;
    }
    .action-btn {
        font-size: 11px;
    }
    .search-result {
        height: 68%;
    }
    .search-field-section {
        display: flex;
        flex-direction: row;
    }
}

.search-criteria ion-col>ion-label {
    font-size: 13px;
}

.search-criteria>ion-card-content>ion-row {
    width: 100%;
}

.ios .pallet-body {
    height: 93%;
}

.ios .pallet-body ion-card-title {
    font-size: 24px;
    font-weight: normal;
}

.ios {
    letter-spacing: 0.5px;
}

.ios .pallet-items ion-card-header {
    padding: 12px 12px 0px 14px;
}

.ios .search-result.pallet-items>ion-card-content>ion-content>ion-card>ion-card-content>div>ion-card>ion-card-content {
    padding: 0;
    margin: -11px;
}

.ios .pallet-items ion-card {
    margin: 16px 10px 16px 16px;
    padding: 5px;
}


/* Add update pallet css */

.add-update-pallet ion-col>ion-label {
    font-size: 13px;
}

.add-update-pallet>ion-card-content>ion-row {
    width: 100%;
}

.add-update-pallet-content {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #f2f2f2;
}

.ios .add-update-pallet {
    margin-top: 0px;
    margin-bottom: 11px;
}

.add-update-pallet-header {
    padding-bottom: 0px;
}

.add-update-pallet ion-input,
.pallet-modal ion-input,
.receive-modal ion-input,
.direct-to-store-modal ion-input,
.product-update-modal ion-input{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    background-color: #ffffff;
    font-size: 12px;
    color: #000000;
}
.product-addupdate-modal ion-input{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    background-color: #ffffff;
    font-size: 12px;
    color: #000000;
}

.add-update-pallet ion-textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 60px;
    background-color: #ffffff;
    font-size: 12px;
    color: #000000;
    margin-top: 0px;
}

.add-update-pallet ion-select,
.pallet-status-modal ion-select {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    background-color: #ffffff;
    display: inline-flex;
    --padding-top: 5px;
    --padding-bottom: 0px;
    font-size: 12px;
    color: #000000;
}

@media screen and (min-width: 480px) {
    .pallet-body {
        width: 70%;
        min-width: 500px;
        height: 91%;
        overflow-y: auto !important;
        overflow-x: auto !important;
        align-items: center;
        align-self: center;

    }
    .add-update-pallet ion-input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        height: 26px;
        background-color: #ffffff;
    }
    .add-update-pallet ion-textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        height: 54px;
        background-color: #ffffff;
    }
    .add-update-pallet ion-select {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        height: 26px;
        background-color: #ffffff;
        display: inline-flex;
        --padding-top: 4px;
    }
    .action-btn {
        font-size: 11px;
    }
    .search-result {
        height: 68%;
    }
    .search-field-section {
        display: flex;
        flex-direction: row;
    }
    .pallet-status-modal .modal-wrapper.sc-ion-modal-md {
        width: 30%;
        min-width: 336px;
        height: 40%;
    }
    .pallet-modal .modal-wrapper.sc-ion-modal-md {
        width: 30%;
        min-width: 336px;
        height: 74%;
    }
    .receive-modal .modal-wrapper.sc-ion-modal-md {
        width: 30%;
        min-width: 336px;
        height: 45%;
    }
    
}

.add-update-pallet ion-card-content {
    padding: 0;
    margin: 0;
}

.ios .add-update-pallet.pallet-items>ion-card-content>ion-content>ion-card>ion-card-content>div>ion-card>ion-card-content {
    padding: 0;
    margin: -11px;
}

.item-content-icon {
    width: 5%;
    min-width: 20px;
    height: 96%;
    float: right;
    cursor: pointer;
    padding: 0 16px 0 10px;
}

.v-center {
    align-self: center;
}

.status-fab {
    height: 20px;
    width: 20px;
    opacity: 1;
    --box-shadow: none;
}

.legend-fab {
    height: 18px;
    width: 18px;
    opacity: 1;
    --box-shadow: none;
    margin: 3px;
}

.received-status {
    --background: #47FFAC;
}

.variance-status {
    --background: #FF2121;
}

.in-depot-status {
    --background: #FFA054;
}

.others-status {
    --background: #FFFA54;
}

.legend-section {
    width: 100%;
    white-space: nowrap;
}

.header-section {
    width: 50%;
}

.center {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 479px) {
    .header-section {
        width: 100%;
        padding-left: 5px;
    }
    .header-caption {
        margin: 0 !important;
        padding: 0 !important;
        padding-left: 6px;
        min-height: 70px;
    }
    .legend-section {
        font-size: 11px;
    }
    .pad-2 {
        padding-top: 2px;
    }
}

.legend-item {
    display: flex;
    flex-direction: row;
}

.direct-to-store-modal .modal-wrapper.sc-ion-modal-md {
    width: 30%;
    min-width: 336px;
    height: 43%;
}

.label-content-table, td, th {
    border: 1px solid black;
}

.label-content-table {
    width: 100%;
    height:40%;
    border-collapse: collapse;
}

@media screen and (max-width: 479px) {
    .header-section {
        width: 100%;
        padding-left: 5px;
    }
    .header-caption {
        margin: 0 !important;
        padding: 0 !important;
        padding-left: 6px;      
    }
    .legend-section {
        font-size: 11px;
    }
    .pad-2 {
        padding-top: 2px;
    }
    .height-97{
        height: 97%;
    }  
    .large-button{
        height: 79px;
    }
    .large-input{
        height: 45px !important;
        font-size: 22px !important;
    }
    .large-label{
        font-size: 16px !important;
    }

    .height-65{
        height: 65%;
    }

    .height-96{
        height: 96%;
    }

    .min-height-30{
        min-height: 30%;
        height: auto;
    }

    .min-height-40{
        min-height: 40px !important;
    }
}