.button {
    border-radius: 3px;
    width: 95%;
}

body {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.card-content-ios {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    line-height: 1.4;
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}