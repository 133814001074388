.searchcriteria
{
    position: sticky;
    z-index: 9;
    height: 25%;
    min-height: 250px;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    background-color: #f2f2f2;
}
.search-button {
    margin-top: 20px!important;
    height: 40px!important;
    font-size: 16px!important;
    font-weight: bold!important;
}

.scaninput {
    margin-top: 20px!important;
    padding: 20px!important;
    font-size: 18px!important;
}

.searchcriteria h1 {
    font-size: 24px!important;
    padding-top: 15px!important;
    text-align: center!important;
}
.distribution-card1 .center
{
    font-size: 24px!important;
    font-weight: bold!important;
    padding-top: 22px!important;
    padding-right:30px!important;
    /* text-align: center; */
}

.searchcriteria ion-input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    background-color: #ffffff;
    font-size: 12px;
    color: #000000;
}





